// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_report__210jP {\n}\n\n.style_summaries__2mx8Z {\n  display: grid;\n  grid-template: auto / 1fr 1fr;\n  grid-gap: 1.5rem;\n  gap: 1.5rem;\n  margin: 1.5rem 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/report/style.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,gBAAW;EAAX,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".report {\n}\n\n.summaries {\n  display: grid;\n  grid-template: auto / 1fr 1fr;\n  gap: 1.5rem;\n  margin: 1.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"report": "style_report__210jP",
	"summaries": "style_summaries__2mx8Z"
};
export default ___CSS_LOADER_EXPORT___;
