// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tooltip__3e26k {\n  background-color: hsl(var(--foreground-hsl));\n  border-radius: 0.25rem;\n  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 0%, 0.5);\n  color: hsl(var(--background-hsl));\n  font-size: 0.875rem;\n  font-weight: bold;\n  max-width: 12rem;\n  padding: 0.125rem 0.5rem;\n  text-align: center;\n  width: max-content;\n  z-index: 10;\n  transition: none 0.25s ease-in-out;\n  transition-property: opacity, visibility, margin;\n}\n\n.style_tooltip__3e26k:not(.style_active__LWHR2) {\n  opacity: 0;\n  pointer-events: none;\n  visibility: hidden;\n}\n\n.style_tooltip__3e26k.style_active__LWHR2 {\n  margin-top: 0.375rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/tooltip/style.module.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,sBAAsB;EACtB,kDAAkD;EAClD,iCAAiC;EACjC,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,wBAAwB;EACxB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,kCAAkC;EAClC,gDAAgD;AAClD;;AAEA;EACE,UAAU;EACV,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".tooltip {\n  background-color: hsl(var(--foreground-hsl));\n  border-radius: 0.25rem;\n  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 0%, 0.5);\n  color: hsl(var(--background-hsl));\n  font-size: 0.875rem;\n  font-weight: bold;\n  max-width: 12rem;\n  padding: 0.125rem 0.5rem;\n  text-align: center;\n  width: max-content;\n  z-index: 10;\n  transition: none 0.25s ease-in-out;\n  transition-property: opacity, visibility, margin;\n}\n\n.tooltip:not(.active) {\n  opacity: 0;\n  pointer-events: none;\n  visibility: hidden;\n}\n\n.tooltip.active {\n  margin-top: 0.375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "style_tooltip__3e26k",
	"active": "style_active__LWHR2"
};
export default ___CSS_LOADER_EXPORT___;
