// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_footer__3n-FN {\n  padding: 3rem;\n  text-align: center;\n}\n\n.style_footer__3n-FN a {\n  font-weight: bold;\n}\n\n.style_footer__3n-FN ul {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-bottom: 0.75rem;\n}\n\n.style_footer__3n-FN li + li {\n  margin-left: 0.75rem;\n}\n\n.style_footer__3n-FN p {\n  font-size: 0.75em;\n}\n", "",{"version":3,"sources":["webpack://src/components/footer/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".footer {\n  padding: 3rem;\n  text-align: center;\n}\n\n.footer a {\n  font-weight: bold;\n}\n\n.footer ul {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-bottom: 0.75rem;\n}\n\n.footer li + li {\n  margin-left: 0.75rem;\n}\n\n.footer p {\n  font-size: 0.75em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "style_footer__3n-FN"
};
export default ___CSS_LOADER_EXPORT___;
