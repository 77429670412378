// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_entity__2GSij {\n  align-items: center;\n  display: flex;\n  max-width: 100%;\n}\n\n.style_entity__2GSij .style_image__CHPho {\n  border-radius: 0.125rem;\n  background-color: hsla(0, 0%, 0%, 0.5);\n  display: block;\n  height: 2em;\n  image-rendering: crisp-edges;\n  width: 2em;\n}\n\n.style_entity__2GSij .style_image__CHPho + .style_text__3icLI {\n  margin-left: 0.375rem;\n}\n\n.style_entity__2GSij .style_text__3icLI {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://src/components/entity/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,sCAAsC;EACtC,cAAc;EACd,WAAW;EACX,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".entity {\n  align-items: center;\n  display: flex;\n  max-width: 100%;\n}\n\n.entity .image {\n  border-radius: 0.125rem;\n  background-color: hsla(0, 0%, 0%, 0.5);\n  display: block;\n  height: 2em;\n  image-rendering: crisp-edges;\n  width: 2em;\n}\n\n.entity .image + .text {\n  margin-left: 0.375rem;\n}\n\n.entity .text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entity": "style_entity__2GSij",
	"image": "style_image__CHPho",
	"text": "style_text__3icLI"
};
export default ___CSS_LOADER_EXPORT___;
