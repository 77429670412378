// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_menu__2KaJC {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  padding: 1.5rem;\n}\n\n.style_menu__2KaJC ul {\n  align-items: center;\n  display: flex;\n  flex-grow: 1;\n  justify-content: space-between;\n  margin-left: 3rem;\n}\n\n.style_menu__2KaJC li + li {\n  margin-left: 1.5rem;\n}\n\n.style_menu__2KaJC li {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/components/topbar/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".menu {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  padding: 1.5rem;\n}\n\n.menu ul {\n  align-items: center;\n  display: flex;\n  flex-grow: 1;\n  justify-content: space-between;\n  margin-left: 3rem;\n}\n\n.menu li + li {\n  margin-left: 1.5rem;\n}\n\n.menu li {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "style_menu__2KaJC"
};
export default ___CSS_LOADER_EXPORT___;
