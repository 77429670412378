// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_list__GgcED li {\n  display: block;\n}\n\n.style_list__GgcED li + li {\n  margin-top: 0.75rem;\n}\n\n.style_list__GgcED a,\n.style_list__GgcED small,\n.style_list__GgcED strong {\n  display: block;\n}\n\n.style_list__GgcED strong {\n  display: block;\n}\n", "",{"version":3,"sources":["webpack://src/components/history/style.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".list li {\n  display: block;\n}\n\n.list li + li {\n  margin-top: 0.75rem;\n}\n\n.list a,\n.list small,\n.list strong {\n  display: block;\n}\n\n.list strong {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "style_list__GgcED"
};
export default ___CSS_LOADER_EXPORT___;
