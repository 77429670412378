// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_main__2tLVg {\n  flex-grow: 1;\n  padding: 0 3rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/app/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".main {\n  flex-grow: 1;\n  padding: 0 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "style_main__2tLVg"
};
export default ___CSS_LOADER_EXPORT___;
