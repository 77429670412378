// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_summary__1HWJt {\n}\n\n.style_summary__1HWJt h1 {\n  padding: 0.375rem;\n}\n\n.style_summary__1HWJt h1 span {\n  font-size: 1.5em;\n  margin-right: 0.375rem;\n}\n\n.style_summary__1HWJt ul {\n  background-color: hsla(var(--foreground-hsl), 0.05);\n  padding: 0.1875rem;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.style_summary__1HWJt li {\n  margin: 0.1875rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/summary/style.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,mDAAmD;EACnD,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".summary {\n}\n\n.summary h1 {\n  padding: 0.375rem;\n}\n\n.summary h1 span {\n  font-size: 1.5em;\n  margin-right: 0.375rem;\n}\n\n.summary ul {\n  background-color: hsla(var(--foreground-hsl), 0.05);\n  padding: 0.1875rem;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.summary li {\n  margin: 0.1875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": "style_summary__1HWJt"
};
export default ___CSS_LOADER_EXPORT___;
