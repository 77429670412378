// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_table__1VqUz {\n  table-layout: fixed;\n  width: 100%;\n}\n\n.style_table__1VqUz th {\n  font-weight: bold;\n}\n\n.style_table__1VqUz th button {\n  cursor: pointer;\n  display: inline-block;\n  width: 100%;\n  text-align: inherit;\n}\n\n.style_table__1VqUz th:nth-child(5),\n.style_table__1VqUz td:nth-child(5),\n.style_table__1VqUz th:nth-child(6),\n.style_table__1VqUz td:nth-child(6) {\n  text-align: center;\n}\n\n.style_table__1VqUz th:nth-child(7),\n.style_table__1VqUz td:nth-child(7) {\n  text-align: right;\n}\n\n.style_table__1VqUz th:nth-child(n + 5),\n.style_table__1VqUz td:nth-child(n + 5) {\n  width: 4.5rem;\n}\n\n.style_table__1VqUz td,\n.style_table__1VqUz th {\n  padding: 0.375rem;\n}\n\n.style_table__1VqUz tr:nth-child(2n + 1) td {\n  background-color: hsla(var(--foreground-hsl), 0.05);\n}\n", "",{"version":3,"sources":["webpack://src/components/table/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,mBAAmB;AACrB;;AAEA;;;;EAIE,kBAAkB;AACpB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,mDAAmD;AACrD","sourcesContent":[".table {\n  table-layout: fixed;\n  width: 100%;\n}\n\n.table th {\n  font-weight: bold;\n}\n\n.table th button {\n  cursor: pointer;\n  display: inline-block;\n  width: 100%;\n  text-align: inherit;\n}\n\n.table th:nth-child(5),\n.table td:nth-child(5),\n.table th:nth-child(6),\n.table td:nth-child(6) {\n  text-align: center;\n}\n\n.table th:nth-child(7),\n.table td:nth-child(7) {\n  text-align: right;\n}\n\n.table th:nth-child(n + 5),\n.table td:nth-child(n + 5) {\n  width: 4.5rem;\n}\n\n.table td,\n.table th {\n  padding: 0.375rem;\n}\n\n.table tr:nth-child(2n + 1) td {\n  background-color: hsla(var(--foreground-hsl), 0.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "style_table__1VqUz"
};
export default ___CSS_LOADER_EXPORT___;
