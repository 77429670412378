// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_ships__cAcVH {\n  display: flex;\n  overflow-x: auto;\n}\n\n.style_ships__cAcVH > * {\n  flex: 0 0 auto;\n}\n\n.style_ships__cAcVH > * + * {\n  margin-left: 0.375rem;\n}\n\n.style_ships__cAcVH {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.style_ships__cAcVH::-webkit-scrollbar {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://src/components/row/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;AACA;EACE,aAAa;AACf","sourcesContent":[".ships {\n  display: flex;\n  overflow-x: auto;\n}\n\n.ships > * {\n  flex: 0 0 auto;\n}\n\n.ships > * + * {\n  margin-left: 0.375rem;\n}\n\n.ships {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.ships::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ships": "style_ships__cAcVH"
};
export default ___CSS_LOADER_EXPORT___;
