// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_article__2gOSe > * + * {\n  margin-top: 0.75rem;\n}\n\n.style_article__2gOSe h1 {\n  font-size: 1.5em;\n}\n\n.style_article__2gOSe ul {\n  padding-left: 1.5rem;\n  list-style: disc;\n}\n\n.style_article__2gOSe a {\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://src/components/article/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".article > * + * {\n  margin-top: 0.75rem;\n}\n\n.article h1 {\n  font-size: 1.5em;\n}\n\n.article ul {\n  padding-left: 1.5rem;\n  list-style: disc;\n}\n\n.article a {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"article": "style_article__2gOSe"
};
export default ___CSS_LOADER_EXPORT___;
