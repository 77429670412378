// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_instructions__115ld {\n  justify-content: center;\n  flex-direction: column;\n  display: flex;\n  height: 100%;\n  text-align: center;\n  align-items: center;\n}\n\n.style_instructions__115ld h1 {\n  font-size: 2em;\n  margin-bottom: 0.75rem;\n  color: hsla(var(--foreground-hsl), 0.5);\n}\n\n.style_instructions__115ld p {\n  max-width: 72ch;\n}\n", "",{"version":3,"sources":["webpack://src/components/welcome/style.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,uCAAuC;AACzC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".instructions {\n  justify-content: center;\n  flex-direction: column;\n  display: flex;\n  height: 100%;\n  text-align: center;\n  align-items: center;\n}\n\n.instructions h1 {\n  font-size: 2em;\n  margin-bottom: 0.75rem;\n  color: hsla(var(--foreground-hsl), 0.5);\n}\n\n.instructions p {\n  max-width: 72ch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructions": "style_instructions__115ld"
};
export default ___CSS_LOADER_EXPORT___;
